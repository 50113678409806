export const emailValidator = (email: string) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const nameValidator = (name: string) => {
  return /^[a-zA-Z ]{2,30}$/.test(name);
};

export const characterAndSpaceValidator = (str: string) => {
  return /^[a-zA-Z ]+$/i.test(str);
};

export const passwordValidator = (password: string) => {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(
    password
  );
};

export const characterAndNumbersValidator = (str: string) => {
  return /^[a-zA-Z0-9]+$/i.test(str);
};

// Not in use anywhere yet
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
