import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { formEvent, trackleadform } from "@/analytics/analytics";

import { createHubspotLead } from "@/client/OneistoxApiClient";

import Box from "@/design-system/Box";
import TextField from "@/design-system/Form/TextField";
import Icon from "@/design-system/Icon";
import useMediaQuery from "@/design-system/useMediaQuery";

import TransparentButton from "@/design-system-v2/Button/TransparentButton";

import Typography from "@/design-system-v3/Typography";

import { useAppSelector } from "@/hooks/hooks";

import { PreviousRouteProvider } from "@/pages/_app";

import { emailValidator } from "@/utility/validator";

import { getScrollPositionByPercentage } from "@/utils/utils";

import { theme } from "@/theme";

const { colors } = theme;

const SubscriptionBox = ({ parentStyle = {} }: { parentStyle?: any }) => {
  const router = useRouter();
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [inputFocus, setInputFocus] = useState(false);
  // const time = useScreenDuration();
  const { previousRoute } = useContext(PreviousRouteProvider);
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);

  const isMobileScreen = useMediaQuery(`(max-width:${theme.breakpoints.md})`);

  const [subscribed, setSubscribed] = useState({} as any);
  useEffect(() => {
    window.onbeforeunload = () => {
      if (inputFocus) {
        window.dataLayer?.push({
          event: "formAbandonment",
          eventCategory: "Form Abandonment",
          eventAction: "->email",
          "gtm.elementId": "newsLetter",
          "gtm.formId": "newsLetter",
        });
        // const mixPanelTrackObj = {
        //   Page_path: window.location.pathname,
        //   Current_page: window.location.href,
        //   Page_Referrer: previousRoute,
        //   percent_scrolled: getScrollPositionByPercentage(),
        //   // on_screen_duration: time.formattedTime,
        //   formId: "join_our_newsletter",
        //   isLoggedIn: loginDetails.isAuthenticated,
        // };
        // formEvent("newsletter", mixPanelTrackObj);
      }
    };
  }, [inputFocus]);

  // useEffect(() => {
  //   const handler = () => {
  //     if (inputFocus) {
  //       const newDta = {
  //         pageReferrer: previousRoute,
  //         scrollPercentage: getScrollPositionByPercentage(),
  //         // screenDuration: time.formattedTime,
  //         currentPage: window.location.href,
  //         pagePath: window.location.pathname,
  //         formId: "request_a_call_back",
  //         isLoggedIn: loginDetails.isAuthenticated,
  //       };
  //       formEvent("Form_abondonment", newDta);
  //     }
  //   };
  //   router.events.on("routeChangeStart", handler);
  //   return () => {
  //     router.events.off("routeChangeStart", handler);
  //   };
  // }, [inputFocus]);

  useEffect(() => {
    if (inputFocus) {
      const newDta = {
        pageReferrer: previousRoute,
        scrollPercentage: getScrollPositionByPercentage(),
        // screenDuration: time.formattedTime,
        currentPage: window.location.href,
        pagePath: window.location.pathname,
        formId: "join_our_newsletter",
        isLoggedIn: loginDetails.isAuthenticated,
      };
      formEvent("Form_start", newDta);
    }
  }, [inputFocus]);

  const submitHandler = async () => {
    if (!emailValidator(input)) {
      setError(true);
    } else {
      try {
        const data = {
          event_type: "Subscribed",
          formUrl: window.location.href,
          tokens: {
            accountCreated: false,
            lifecyclestage: "subscriber",
          },
          extraData: {},

          pageUrl: window.location.href,
          landingPageUrl: sessionStorage.getItem("initial_landing_page"),
          source: router?.query?.utm_source,
          formId: "footer_newsletter",
        };
        // const mixPanelTrackObj = {
        //   email: input,
        //   Page_path: window.location.pathname,
        //   Current_page: window.location.href,
        //   Page_Referrer: previousRoute,
        //   percent_scrolled: getScrollPositionByPercentage(),
        //   // on_screen_duration: time.formattedTime,
        //   loginDetails: loginDetails.userData,
        //   isLoggedIn: loginDetails.isAuthenticated,
        // };
        // trackleadform("newsletter", mixPanelTrackObj);
        window.dataLayer?.push({
          event: "gtm.formSubmit",
          "gtm.elementId": "newsletter",
          "gtm.formId": "newsletter",
          "gtm.triggers": "",
        });
        setCanSubmit(false);
        const res = await createHubspotLead(data, input);
        setCanSubmit(true);

        setSubscribed({ ...subscribed, status: res.status, res: res.data });
      } catch (error) {
        console.log(error);
        setSubscribed({ ...subscribed, status: 409, res: "Already Exists" });
      }
      setError(false);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    if (emailValidator(e.target.value)) {
      setError(false);
    }
    setSubscribed({});
  };

  return (
    <Box
      borderBottom={{ md: `1px solid ${theme.colors.gray[800]}` }}
      gridColumn={{ md: "1 / span 4" }}
      p={{ xs: "24px 14px", md: "80px 16px 40px" }}
      textAlign={{ md: "center" }}
      style={parentStyle}
    >
      <Typography varient="heading5" weightVarient="semibold" mb={"16px"} color={"base.white"}>
        Join our newsletter
      </Typography>

      <InputBoxStyle padding={{ xs: "4px", md: "8px" }}>
        <InputStyle
          name="email"
          value={input}
          mb={"0px"}
          onFocus={() => {
            setInputFocus(true);
          }}
          size="lg"
          placeholder="Enter your email"
          icon={<Icon type={"mail-01"} color={theme.colors.gray[500]} varient={"line"} size={24} />}
          // value={input}
          id="form_email"
          onChange={handleChange}
        />

        <TransparentButtonStyled
          color="dark"
          label={isMobileScreen ? "" : canSubmit ? "Submit" : "Submit..."}
          size="xl"
          onClick={submitHandler}
          className={"join_our_newsletter"}
          suffixIcon={
            <Icon type="chevron-right" size={24} color={theme.colors.base.white} varient="line" />
          }
        />
        {/* <TransparentButton color="dark" size="xl" label={'asdf'} suffixIcon={<Icon type="" />} /> */}
      </InputBoxStyle>
      {error && (
        <Box>
          <Typography varient="bodyxs" color={theme.colors.error[500]}>
            Please Enter Valid Email Address
          </Typography>
        </Box>
      )}

      {subscribed.res && (
        <Box>
          <Typography varient="bodyxs" color={theme.colors.success[500]}>
            Thank you for signing up.
          </Typography>
        </Box>
      )}
      {subscribed.status === 409 && <Typography varient="bodyxs">Already Subscribed</Typography>}
      <Typography varient={"bodym"} color={"gray.500"}>
        We’ll send you a nice letter once per week. No spam.
      </Typography>
    </Box>
  );
};

export default SubscriptionBox;

const InputBoxStyle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: ${colors.gray[300]};
  border-radius: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  max-width: 600px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(27.850000381469727px);
  box-shadow: 0px -4px 52px 0px rgba(0, 0, 0, 0.12);
`;
const InputStyle = styled(TextField)`
  min-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  border: unset;
  font-size: 20px;
  color: ${colors.gray[300]};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition:
      color 9999s ease-out,
      background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
  }

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    border: none;
  }

  background-color: inherit;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-height: 30px;
  }
`;

const TransparentButtonStyled = styled(TransparentButton)`
  border-radius: 40px;
  height: 60px;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.1);
`;
